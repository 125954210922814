export const paragraphAnimation = {
    initial: 'hidden',
    whileInView: 'visible',
    viewport: { once: true },
    transition: { duration: 1 },
    variants: {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 40 },
    },
};

