import { motion } from 'framer-motion';
import React , {useEffect} from 'react';
import { paragraphAnimation } from '../../utils/config';
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import stakeIcon from '../../assets/images/stake.png';


import { useSelector } from 'react-redux';
import './pnl.scss';
import { toast } from 'react-toastify';
import WalletConnectProvider from '@walletconnect/web3-provider'
import utils from '@okwallet/extension/src/utils';

const claimContractAbi = require('../../helper/presale.json')

const vestingContractAbi = require('../../helper/vesting.json')

let web3Modal;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
  },
}

if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required
  })

  console.log(web3Modal)
}


export default function Mint() {
  const [currentRound, setCurrentRound] = React.useState(4);
  const [maxRounds, setMaxRounds] = React.useState(4);
  const [auditData, setAuditData] = React.useState(null);
  const [pnlData, setPnlData] = React.useState(null);
  const web3provider = useSelector(state => state.web3Provider);

  const fetchData = async (round) => {
    try {
      // Fetch PNL data
      const pnlResponse = await fetch(`https://api.alphashards.com/api/pnl-report/${round}`);
  
      if (!pnlResponse.ok) {
        //throw new Error('Failed to fetch PNL data');
      }else{
        const pnlData = await pnlResponse.json();
        setPnlData(pnlData);
      }
  
      
  
      // Fetch audit data
      const auditResponse = await fetch(`https://api.alphashards.com/api/audit-report/${round}`);
  
      if (!auditResponse.ok) {
        //throw new Error('Failed to fetch audit data');
      }else{
        const auditData = await auditResponse.json();
        setAuditData(auditData);
      }
  
      
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Error fetching data from the API');
    }
  };
  

  useEffect(() => {
    if (web3provider) {
      fetchData(currentRound);
    }
  }, [web3provider, currentRound]);

  const handleNextRound = () => {
    setAuditData([])
    setPnlData([])
    if (currentRound < maxRounds) {
      setCurrentRound((prevRound) => prevRound + 1);
    }
  };

  const handlePrevRound = () => {
    setAuditData([])
    setPnlData([])
    if (currentRound > 1) {
      setCurrentRound((prevRound) => prevRound - 1);
    }
  };


  return (
    <>
    <motion.section 
    {...paragraphAnimation}
    id='pnl' className='pnl'>
      <div className='pnl__content'>
        <div className='flex-pnl-container'>
          <h1>ALPHA SYNDICATE</h1>

          <div className='flex-audit-container'>
            <h3>AUDIT REPORT</h3>


            {auditData && (
              <>

                <div className='audit-gap'></div>

                <div className='audit-field-entry'>
                  <div className='audit-field-1'>Total# of Deposits</div>
                  <div className='audit-field-2'>{auditData.total_deposits}</div>
                </div>

                <div className='audit-field-entry'>
                  <div className='audit-field-1'>Total Deposit (S):</div>
                  <div className='audit-field-2'>{`$${auditData.total_deposit_amount}`}</div>
                </div>

                <div className='audit-field-entry'>
                  <div className='audit-field-1'># of Invalid Deposits:</div>
                  <div className='audit-field-2'>{auditData.invalid_deposits_count}</div>
                </div>

                <div className='audit-field-entry'>
                  <div className='audit-field-1'>Max. Allocation ($):</div>
                  <div className='audit-field-2'>{auditData.max_allocation}</div>
                </div>

                <div className='audit-field-entry'>
                  <div className='audit-field-1'>Allocation Used (%):</div>
                  <div className='audit-field-2'>{`${auditData.allocation_used_percentage}%`}</div>
                </div>

                <div className='audit-round-header-bottom'>FULL AUDIT COMPLETED BY ALPHA GOVERNANCE TEAM</div>
              </>
            )}

          </div>

          {pnlData && (
            <>

            <div className='pnl-round-header'>PNL ROUND {currentRound}</div>

            <div className='pnl-gap'></div>

            {/* <div className='pnl-date'>Date: {new Date(pnlData.date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</div> */}

              <div className='pnl-field-entry'>
                <div className='pnl-field-1'>Total# of Deposits</div>
                <div className='pnl-field-2'>{pnlData.total_deposits}</div>
              </div>

              <div className='pnl-field-entry'>
                <div className='pnl-field-1'>Deposit Amount</div>
                <div className='pnl-field-2'>{`$${pnlData.deposit_amount}`}</div>
              </div>

              <div className='pnl-field-entry'>
                <div className='pnl-field-1'>Invalid Deposits Returned</div>
                <div className='pnl-field-2'>{pnlData.invalid_deposits_returned}</div>
              </div>

              <div className='pnl-field-entry'>
                <div className='pnl-field-1'>Max Allocation</div>
                <div className='pnl-field-2'>{pnlData.max_allocation}</div>
              </div>

              <div className='pnl-field-entry'>
                <div className='pnl-field-1'>% Allocation Used</div>
                <div className='pnl-field-2'>{`${pnlData.allocation_used}%`}</div>
              </div>

              <div className='pnl-field-entry'>
                <div className='pnl-field-1'>Total Gross Profits</div>
                <div className='pnl-field-2'>{`$${pnlData.total_gross_profits}`}</div>
              </div>

              <div className='pnl-field-entry'>
                <div className='pnl-field-1'>Total Profit %</div>
                <div className='pnl-field-2'>{`${pnlData.total_profit_percentage}%`}</div>
              </div>

              <div className='pnl-field-entry'>
                <div className='pnl-field-1'>Depositor Net Profit %</div>
                <div className='pnl-field-2'>{`${pnlData.depositor_net_profit_percentage}%`}</div>
              </div>

              <div className='pnl-field-entry'>
                <div className='pnl-field-1'>Depositor Total Payout</div>
                <div className='pnl-field-2'>{`$${pnlData.depositor_total_payout}`}</div>
              </div>

              <div className='pnl-field-entry'>
                <div className='pnl-field-1'>Depositors - {`${pnlData.depositor_percentage}%`}</div>
                <div className='pnl-field-2'>{`$${pnlData.depositors_amount}`}</div>
              </div>

              <div className='pnl-field-entry'>
                <div className='pnl-field-1'>STAKX - {`${pnlData.stakx_percentage}%`}</div>
                <div className='pnl-field-2'>{`$${pnlData.stakx_amount}`}</div>
              </div>

              <div className='pnl-field-entry'>
                <div className='pnl-field-1'>Alpha Treasury - {`${pnlData.alpha_treasury_percentage}%`}</div>
                <div className='pnl-field-2'>{`$${pnlData.alpha_treasury_amount}`}</div>
              </div>

              <div className='pnl-field-entry'>
                <div className='pnl-field-1'>Governance {`${pnlData.governance_percentage}%`}</div>
                <div className='pnl-field-2'>{`$${pnlData.governance_amount}`}</div>
              </div>
            </>
          )}

        </div>

        <div className='pnl-footer'>FULL AUDIT PERFORMED BY ALPHA GOVERNANCE TEAM.</div>
          {/* Navigation buttons with styling */}
          <div className='round-navigation'>
            <button
              className='navigation-button'
              onClick={handlePrevRound}
              disabled={currentRound === 1}
            >
              &lt; Prev Round
            </button>

            <button
              className='navigation-button'
              onClick={handleNextRound}
              disabled={currentRound === maxRounds}
            >
              Next Round &gt;
            </button>
          </div>
        </div>
      </motion.section>
    </>
  );
}
